.i-text{
    font-family: poppins;
    font-weight: 600;
    font-size: 2.5rem;
    position: relative;
    top: 11px;
    left: 20px;
}

.green-text{
    color: #00BF7A;
}

.white-text{
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #00BF7A;
    text-decoration-thickness: 10px;
}

.tile{
    background-color: #fff;
    border-radius: 5px;
    min-height: 30rem;
    margin-top: 40px;
    text-align: center;
}

.tile-bottom-border-red{
    border-bottom: 10px solid #DC3545;
}

.tile-bottom-border-blue{
    border-bottom: 10px solid #007BFF;
}

.tile-bottom-border-purple{
    border-bottom: 10px solid #6F42C1;
}

.company-text{
    color: #000;
    padding: 10px;
}

.date-text{
    color: #000;
    font-weight: 800;
}


