.landing-headline-1{
    color: #fff;
    font-weight: 800;
}

.landing-headline-2{
    color: #00BF7A;
    font-weight: 800;
}

.banner-text{
    padding-top: 160px;
}

.hire-me-button{
    width: 212px;
    height: 53px;
    border-radius: 14px;
    background-color: #00BF7A;
    color: #fff;
    border: 0px;
}

.explore-projects-button{
    width: 282px;
    height: 53px;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0);
    color: #00BF7A;
    border-color: #00BF7A;
    margin-left: 10px;
    border-style: solid;
}

.page-size{
    min-height: 90vh;
}