.heading{
    color: #fff;
    font-family: poppins;
    font-weight: 600;
    font-size: 24px;
  }
  
  .heading-green{
    color: #fff;
    font-family: poppins;
    text-decoration: underline;
    text-decoration-thickness: 10px;
    text-decoration-color: #00BF7A;
    font-weight: 600;
    font-size: 24px;
  }
  
  .i-nav-link{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .i-nav-link:hover{
    color: #fff;
    text-decoration: none;
  }