.default-space-before-heading{
    margin-left: 119px;
}

.achivement-tile{
    margin-top: 40px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
}

.achievement-img{
    max-width: 100%;
}

.achievement-text{
    
}