.contact-content{
    padding-top: 150px;
}

.location-icon{
    height: 50px;
}

.contact-field{
    margin-bottom: 80px;
}

.location-text{
    margin-left: 30px;
}

.full-width{
    width: 100%;
}

.half-width{
    width: 50%;
    margin: 0%;
    padding: 0;
    display: inline-block;
}

.form-input{
    margin-bottom: 20px;
    width: 100%;
    background: #212121;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
}

.phone-number-div{
    padding-right: 10px;
}

.email-div{
    padding-left: 10px;
}

.submit-button{
    width: 100%;
    border-radius: 14px;
    background-color: #00BF7A;
    color: #fff;
    border: 0px;
    height: 40px;
    border: 1px solid #00BF7A;
}

.centered-text{
    text-align: center;
}

.ending-text{
    margin-top: 30px;
}

.heart {
    height: 16px;
}