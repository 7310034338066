.project-img{
    width: 100%;
    padding-top: 20px;
}

.project-tile{
    min-height: 350px;
    background: #2D2C2C;
    box-shadow: 0px 4px 20px rgba(65, 65, 65, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    padding-right: 20px;
    margin-top: 20px;
    text-align: center;
}

.project-tile-bottom-border-red{
    border-bottom: 10px solid #DC3545;
}

.project-tile-bottom-border-blue{
    border-bottom: 10px solid #007BFF;
}

.project-tile-bottom-border-purple{
    border-bottom: 10px solid #6F42C1;
}

.project-text{
   font-family: Poppins;
   font-weight: 600;
   padding-top: 20px;
   padding-bottom: 10px;
}